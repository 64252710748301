import {
  type TextFieldProps,
  type ValidationResult,
  TextField,
  Label,
  Input,
  Text,
  TextArea,
  FieldError,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

type Props = {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  className?: string;
  placeholder?: string;
} & TextFieldProps;

export function InputField({
  className,
  label,
  description,
  isRequired,
  isDisabled,
  placeholder,
  errorMessage,
  ...props
}: Props) {
  return (
    <TextField
      {...props}
      isDisabled={isDisabled}
      isRequired={isRequired}
      className={twMerge('flex h-auto flex-col', className)}
      aria-label={label ?? 'Input field'}
    >
      {label && (
        <Label className={twMerge('mb-1 text-left text-xs text-[#262626]', isDisabled && 'opacity-50')}>{`${label} ${
          isRequired ? '*' : ''
        }`}</Label>
      )}
      <Input
        className="box-border min-h-[44px] rounded-[4px] border-error text-[14px] text-[#000000d9] valid:border-[#d9d9d9] hover:border-primary focus:border-primary disabled:border-[#d9d9d9] disabled:opacity-50"
        placeholder={placeholder}
      />
      {description && (
        <Text slot="description" className="mt-1 text-[12px] text-[#00000099]">
          {description}
        </Text>
      )}
      <FieldError className="mt-1 text-left text-[12px] text-error">{errorMessage}</FieldError>
    </TextField>
  );
}

export function TextAreaField({
  className,
  label,
  description,
  isRequired,
  isDisabled,
  placeholder,
  errorMessage,
  ...props
}: Props & { rows?: number }) {
  return (
    <TextField
      {...props}
      isDisabled={isDisabled}
      isRequired={isRequired}
      className={twMerge('flex h-auto flex-col', className)}
    >
      {label && (
        <Label className={twMerge('mb-1 text-left text-xs text-[#262626]', isDisabled && 'opacity-50')}>{`${label} ${
          isRequired ? '*' : ''
        }`}</Label>
      )}
      <TextArea
        className="box-border h-full rounded-[4px] border-error text-[14px] text-[#000000d9] valid:border-[#d9d9d9] hover:border-primary focus:border-primary disabled:border-[#d9d9d9] disabled:opacity-50"
        placeholder={placeholder}
        rows={props.rows ?? 5}
      />
      {description && (
        <Text slot="description" className="mt-1 text-[12px] text-[#00000099]">
          {description}
        </Text>
      )}
      <FieldError className="mt-1 text-left text-[12px] text-error">{errorMessage}</FieldError>
    </TextField>
  );
}
